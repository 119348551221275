import React from 'react';

const ArrowIcon = ({
  type = 'next',
  src
}: {
  type?: 'next' | 'prev';
  src?: string;
}) => {
  const srcType = src ? src.split('.').pop() : 'png';
  const [isError, setIsError] = React.useState(false);
  return (
    <div className="arrow-icon" custom-type={srcType} data-type={type}>
      {src && !isError ? (
        <img
          className="arrow custom"
          src={src}
          alt="arrow"
          onError={() => {
            setIsError(true);
          }}
        />
      ) : (
        <>
          <img
            className="arrow desktop"
            src="/asset/sb-arrow.png"
            alt="arrow"
          />
          <img
            className="arrow mobile"
            src="/asset/sb-arrow-mobile.png"
            alt="arrow"
          />
        </>
      )}
      <style jsx>{`
        .arrow-icon {
          width: 100%;
          aspect-ratio: 1;
          text-align: center;
        }
        .arrow-icon[data-type='prev']:not(:has(.custom)) {
          transform: scaleX(-1);
        }

        .arrow.mobile {
          display: none;
        }
        .arrow {
          height: 100%;
          width: auto;
        }
        .arrow-icon[custom-type='gif'] .arrow {
          width: 100%;
          height: auto;
        }

        @media (max-width: 768px) {
          .arrow-icon .arrow.mobile {
            display: inline-block;
          }
          .arrow-icon .arrow.desktop {
            display: none;
          }
        }
        .arrow-icon[custom-type='png'] .arrow {
          --translate-to: 90%;
          animation: arrow-animation 2s ease-in-out infinite;
        }

        .arrow-icon[data-type='prev'] .arrow.custom {
          --translate-to: -90%;
        }

        @keyframes arrow-animation {
          0%,
          10% {
            opacity: 0;
            transform: translateX(0);
          }
          30% {
            opacity: 1;
            transform: translateX(0);
          }
          70% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: translateX(var(--translate-to));
          }
        }
      `}</style>
    </div>
  );
};

const ArrowSlider = ({
  onNext,
  onPrev,
  disableNext,
  disablePrev,
  forceSmall,
  settings
}: {
  onNext: () => void;
  onPrev: () => void;
  disableNext?: boolean;
  disablePrev?: boolean;
  forceSmall?: boolean;
  settings?: {
    enableCustomNavigation?: boolean;
    nextButton?: string;
    prevButton?: string;
  };
}) => {
  const { enableCustomNavigation, nextButton, prevButton } = settings || {};

  return (
    <>
      <div
        className={`arrow prev ${forceSmall ? 'forceSmall' : ''} ${
          disablePrev ? 'disabled' : ''
        }`}
        onClick={onPrev}
      >
        <ArrowIcon type="prev" src={enableCustomNavigation ? prevButton : ''} />
      </div>

      <div
        className={`arrow next ${forceSmall ? 'forceSmall' : ''} ${
          disableNext ? 'disabled' : ''
        }`}
        onClick={onNext}
      >
        <ArrowIcon type="next" src={enableCustomNavigation ? nextButton : ''} />
      </div>

      <style jsx>{`
        .arrow {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          z-index: 40;
          cursor: pointer;
          width: 100px;
          transition: opacity 0.3s linear;
          overflow-x: hidden;
        }
        .disabled {
          pointer-events: none;
          visibility: hidden;
        }
        .arrow:hover,
        .arrow.hide:hover {
          opacity: 1;
        }
        .next {
          right: 0;
        }
        .prev {
          left: 0;
          text-align: right;
        }

        .forceSmall.arrow {
          width: 50px;
        }
        .forceSmall.next {
          right: 10px;
        }
        .forceSmall.prev {
          left: 10px;
        }

        @media (max-width: 768px) {
          .arrow {
            width: 50px;
          }
        }
        @media (max-height: 600px) {
          .arrow {
            width: 50px;
          }
          .next {
            right: 20px;
          }
          .prev {
            left: 20px;
          }
        }
        @media (orientation: portrait) {
          .next {
            right: 10px;
          }
          .prev {
            left: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default ArrowSlider;
