import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop, { Crop, ReactCropProps } from 'react-image-crop';
const CropImage = ({
  imgSrc,
  setCropFile,
  onClose,
  aspect,
  maxWidth,
  maxHeight
}: {
  imgSrc?: string;
  setCropFile?: (value: File) => void;
  onClose?: () => void;
  aspect?: number;
} & Partial<ReactCropProps>) => {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: maxWidth || 160,
    aspect: 16 / 9,
    x: 0,
    y: 0,
    height: 0
  });
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (aspect) {
      setCrop((prev) => ({ ...prev, aspect }));
    }
  }, [aspect]);

  const initWithRatio = (width, height, aspect) => {
    if (aspect > 1) {
      if(1/aspect * width <= height) {
        return {
          width: width, height: 1/aspect * width
        }
      }
      else {
        return {
          width: aspect * height, height: height
        }
      }
    } else {
      if (aspect * height <= width) {
        return {
          width: aspect * height, height: height
        }
      }
      else {
        return {
          width: width, height: 1/aspect * width
        }

      }
    }
  }

  useEffect(() => {
    if (imgRef.current) {
      const { width: imgWidth, height: imgHeight } = imgRef.current;
      const {width, height} = initWithRatio(imgWidth, imgHeight, aspect);
      setCrop({...crop, width, height});
    }
  }, [imgRef.current, crop.aspect]);

  const generateCanvas = (): any => {
    if (!crop || !imgRef.current) {
      return;
    }
    const reqMaxWidth = maxWidth || 512;
    const image = imgRef.current;
    const {width, height} = initWithRatio(image.width, image.height, aspect);
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = 1;
    const _crop =
      crop.width < 1 || crop.height < 1
        ? { ...crop, width, height, x: 0, y: 0 }
        : crop;
    const maxCurrentWidth = _crop.width * scaleX;
    const reqToScale =
      maxCurrentWidth > reqMaxWidth ? reqMaxWidth / maxCurrentWidth : 1;
    canvas.width = _crop.width * pixelRatio * scaleX * reqToScale;
    canvas.height = canvas.width / crop.aspect;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      image,
      _crop.x * scaleX,
      _crop.y * scaleY,
      _crop.width * scaleX,
      _crop.height * scaleY,
      0,
      0,
      _crop.width * scaleX * reqToScale,
      _crop.height * scaleY * reqToScale
    );
    if (canvas.width < 1 || canvas.height < 1) return undefined;
    return canvas;
  };
  return (
    <>
      <div className="crop-text">
        <p>Adjust and crop your Featured Image</p>
      </div>
      <div className="CropImage">
        <div className="overlay-content">
          <ReactCrop
            src={imgSrc}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => {
              if((c.height !== 0 && c.width !== 0)) {
                setCrop(c);
              }
            }}
            imageStyle={{
              maxHeight: `calc(100vh - 200px)`
            }}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            crossorigin="anonymous"
          />
        </div>
      </div>
      <div className="but-div">
        <button
          className="btn border border-light btn-round cancel"
          onClick={(e) => {
            e.preventDefault();
            e.nativeEvent?.stopImmediatePropagation();
            onClose();
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-light  btn-round save"
          onClick={(e) => {
            e.preventDefault();
            e.nativeEvent?.stopImmediatePropagation();
            setCropFile(generateCanvas());
          }}
        >
          Save
        </button>
      </div>
      <style jsx global>{`
        .ReactCrop {
          position: relative;
          display: inline-block;
          cursor: crosshair;
          overflow: hidden;
          max-width: 95%;
        }
        .ReactCrop:focus {
          outline: none;
        }
        .ReactCrop--disabled,
        .ReactCrop--locked {
          cursor: inherit;
        }
        .ReactCrop__image {
          display: block;
          max-width: 100%;
          touch-action: none;
        }
        .ReactCrop__crop-selection {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate3d(0, 0, 0);
          box-sizing: border-box;
          cursor: move;
          box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
          touch-action: none;
          border: 1px dashed #fff;
        }
        .ReactCrop--disabled .ReactCrop__crop-selection {
          cursor: inherit;
        }
        .ReactCrop--circular-crop .ReactCrop__crop-selection {
          border-radius: 50%;
          box-shadow: 0px 0px 1px 1px #fff, 0 0 0 9999em rgba(0, 0, 0, 0.5);
        }
        .ReactCrop--invisible-crop .ReactCrop__crop-selection {
          display: none;
        }
        .ReactCrop__rule-of-thirds-vt::before,
        .ReactCrop__rule-of-thirds-vt::after,
        .ReactCrop__rule-of-thirds-hz::before,
        .ReactCrop__rule-of-thirds-hz::after {
          content: '';
          display: block;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.4);
        }
        .ReactCrop__rule-of-thirds-vt::before,
        .ReactCrop__rule-of-thirds-vt::after {
          width: 1px;
          height: 100%;
        }
        .ReactCrop__rule-of-thirds-vt::before {
          left: 33.3333%;
          left: 33.3333333333%;
        }
        .ReactCrop__rule-of-thirds-vt::after {
          left: 66.6666%;
          left: 66.6666666667%;
        }
        .ReactCrop__rule-of-thirds-hz::before,
        .ReactCrop__rule-of-thirds-hz::after {
          width: 100%;
          height: 1px;
        }
        .ReactCrop__rule-of-thirds-hz::before {
          top: 33.3333%;
          top: 33.3333333333%;
        }
        .ReactCrop__rule-of-thirds-hz::after {
          top: 66.6666%;
          top: 66.6666666667%;
        }
        .ReactCrop__drag-handle {
          position: absolute;
        }
        .ReactCrop__drag-handle::after {
          position: absolute;
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          background-color: rgba(0, 0, 0, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.7);
          box-sizing: border-box;
          outline: 1px solid transparent;
        }
        .ReactCrop .ord-nw {
          top: 0;
          left: 0;
          margin-top: -5px;
          margin-left: -5px;
          cursor: nw-resize;
        }
        .ReactCrop .ord-nw::after {
          top: 0;
          left: 0;
        }
        .ReactCrop .ord-n {
          top: 0;
          left: 50%;
          margin-top: -5px;
          margin-left: -5px;
          cursor: n-resize;
        }
        .ReactCrop .ord-n::after {
          top: 0;
        }
        .ReactCrop .ord-ne {
          top: 0;
          right: 0;
          margin-top: -5px;
          margin-right: -5px;
          cursor: ne-resize;
        }
        .ReactCrop .ord-ne::after {
          top: 0;
          right: 0;
        }
        .ReactCrop .ord-e {
          top: 50%;
          right: 0;
          margin-top: -5px;
          margin-right: -5px;
          cursor: e-resize;
        }
        .ReactCrop .ord-e::after {
          right: 0;
        }
        .ReactCrop .ord-se {
          bottom: 0;
          right: 0;
          margin-bottom: -5px;
          margin-right: -5px;
          cursor: se-resize;
        }
        .ReactCrop .ord-se::after {
          bottom: 0;
          right: 0;
        }
        .ReactCrop .ord-s {
          bottom: 0;
          left: 50%;
          margin-bottom: -5px;
          margin-left: -5px;
          cursor: s-resize;
        }
        .ReactCrop .ord-s::after {
          bottom: 0;
        }
        .ReactCrop .ord-sw {
          bottom: 0;
          left: 0;
          margin-bottom: -5px;
          margin-left: -5px;
          cursor: sw-resize;
        }
        .ReactCrop .ord-sw::after {
          bottom: 0;
          left: 0;
        }
        .ReactCrop .ord-w {
          top: 50%;
          left: 0;
          margin-top: -5px;
          margin-left: -5px;
          cursor: w-resize;
        }
        .ReactCrop .ord-w::after {
          left: 0;
        }
        .ReactCrop__disabled .ReactCrop__drag-handle {
          cursor: inherit;
        }
        .ReactCrop__drag-bar {
          position: absolute;
        }
        .ReactCrop__drag-bar.ord-n {
          top: 0;
          left: 0;
          width: 100%;
          height: 6px;
          margin-top: -3px;
        }
        .ReactCrop__drag-bar.ord-e {
          right: 0;
          top: 0;
          width: 6px;
          height: 100%;
          margin-right: -3px;
        }
        .ReactCrop__drag-bar.ord-s {
          bottom: 0;
          left: 0;
          width: 100%;
          height: 6px;
          margin-bottom: -3px;
        }
        .ReactCrop__drag-bar.ord-w {
          top: 0;
          left: 0;
          width: 6px;
          height: 100%;
          margin-left: -3px;
        }
        .ReactCrop--new-crop .ReactCrop__drag-bar,
        .ReactCrop--new-crop .ReactCrop__drag-handle,
        .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
          display: none;
        }
        .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
        .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
        .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
        .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
          display: none;
        }
        @media (pointer: coarse) {
          .ReactCrop .ord-n,
          .ReactCrop .ord-e,
          .ReactCrop .ord-s,
          .ReactCrop .ord-w {
            display: none;
          }
          .ReactCrop__drag-handle {
            width: 24px;
            height: 24px;
          }
        }
      `}</style>
      <style jsx>{`
        .crop-text {
          height: 50px;
          width: 100%;
          position: fixed;
          z-index: 90000;
          top: 60px;
          left: 0;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.9);
          overflow-x: hidden;
          transition: 0.5s;
          width: 100%;
          text-align: center;
        }
        .CropImage {
          height: calc(100% - 160px);
          width: 100%;
          position: fixed;
          z-index: 10000;
          top: 110px;
          left: 0;
          background-color: rgba(0, 0, 0, 0.9);
          overflow-x: hidden;
          transition: 0.5s;
        }
        .but-div {
          height: 50px;
          width: 100%;
          position: fixed;
          z-index: 90000;
          bottom: 0px;
          left: 0;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.9);
          overflow-x: hidden;
          transition: 0.5s;
          width: 100%;
          text-align: center;
          padding-top: 10px;
        }
        p {
          transform: translate(0, 18px);
        }
        .cancel {
          width: 100px;
          color: #fff;
          margin-right: 5px;
        }
        .save {
          width: 100px;
          margin-left: 5px;
        }
        .overlay-content {
          position: relative;
          width: 100%;
          text-align: center;
          margin-top: 30px;
        }
      `}</style>
    </>
  );
};
export default CropImage;
