import React, { useRef } from 'react';
import { logClickButton, logEvent } from '../../../../analytics';
import { IStorybook } from '../../../../interfaces';
import {
  DID_CLICK_PAGINATION,
  DID_CLICK_THUMBNAIL
} from '../../../../utils/constants';
import { getFileType } from '../../../../utils/file';
import {
  mapBrowerLanguageToSBLanguage,
  scrollElementToCenterContainer
} from '../../../../utils/window';
import {
  getContentUrl,
  getLanguageJSON,
  getSBPageSetting
} from '../../../utils/storybook';
import ArrowSlider from '../../Common/ArrowSlider';
import { IconArrow } from '../../Common/HubIcons';
import SBThumbnailCover from '../Common/SBThumbnailCover';
import { mapStorybookSettingsStringToObject } from '../../../../mappers/storybook';
import _ from 'lodash';

let scrollTimeout;

const SBMicrositeNavigation = ({
  storybook,
  activePage,
  onNext,
  onPrev,
  onClickPage,
  portrait,
  totalExtraPages = 0,
  onToggle,
  isClickedButtonRef
}: {
  storybook: IStorybook;
  activePage: number;
  onNext: () => void;
  onPrev: () => void;
  onClickPage: (page: number) => void;
  onToggle?: () => void;
  portrait?: boolean;
  totalExtraPages?: number;
  isClickedButtonRef?: React.MutableRefObject<boolean>;
}) => {
  const [showPages, setShowPages] = React.useState(false);
  const containerRef = useRef(null);
  const activeElRef = useRef(null);

  const { pages, settings } = storybook;

  const navSettings = React.useMemo(() => {
    const settingObj = mapStorybookSettingsStringToObject(settings);
    return _.pick(settingObj, [
      'enableCustomNavigation',
      'nextButton',
      'prevButton',
      'hideThumbnails'
    ]);
  }, [settings]);

  const contentLength = pages.length + totalExtraPages;

  const handleAutoScroll = (element, container) => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
    scrollTimeout = setTimeout(() => {
      scrollElementToCenterContainer(element, container);
      scrollTimeout = null;
    }, 10);
  };

  const togglePagesList = (open: boolean) => {
    setShowPages(open);
    logEvent(DID_CLICK_PAGINATION, DID_CLICK_PAGINATION, {
      open
    });
  };

  const handleClickThumbnail = (thumbnailUrl: string, pageNumber: number) => {
    onClickPage(pageNumber);
    if (isClickedButtonRef) {
      isClickedButtonRef.current = true;
    }
    logEvent(DID_CLICK_THUMBNAIL, DID_CLICK_THUMBNAIL, {
      pageNumber: pageNumber + 1,
      thumbnailUrl
    });
  };

  React.useEffect(() => {
    if (activeElRef.current && containerRef.current) {
      handleAutoScroll(activeElRef.current, containerRef.current);
    }
  }, [
    activePage,
    portrait,
    showPages,
    activeElRef.current,
    containerRef.current
  ]);

  const shouldShowToggleBtn =
    !navSettings.hideThumbnails &&
    !showPages &&
    activePage < contentLength - totalExtraPages &&
    pages.length > 1;
  const language = mapBrowerLanguageToSBLanguage(navigator.language);
  return (
    <>
      <div className={`navigation ${portrait ? 'portrait' : 'landscape'}`}>
        <div className={`page-container ${showPages ? 'show' : ''}`}>
          <div
            className="PageList-backdrop fixed-full"
            onClick={() => togglePagesList(false)}
          />
          {!navSettings.hideThumbnails && (
            <div className="PageList">
              <div className="thumbnail-container" ref={containerRef}>
                {pages.map((page, i) => {
                  const { thumbnail, portraitThumbnail } = getContentUrl(
                    page,
                    page.settings
                  );
                  return (
                    <div
                      id={`slide${i}`}
                      ref={activePage === i ? activeElRef : null}
                      className={`thumbnail ${
                        activePage === i ? 'active' : ''
                      }`}
                      key={`${page.url}${i}`}
                    >
                      <SBThumbnailCover
                        url={getLanguageJSON(thumbnail)[language]}
                        portraitUrl={portraitThumbnail}
                        type={getFileType(page.url)}
                        backgroundColor={
                          getSBPageSetting(
                            pages[i]?.settings || '',
                            storybook.settings || ''
                          )?.background
                        }
                        onClick={() => handleClickThumbnail(page.url, i)}
                        disabled={activePage === i}
                        portrait={portrait}
                        objectFit="cover"
                      />
                      <div>{i + 1}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div
            className="close-pages btn"
            onClick={() => {
              togglePagesList(false);
            }}
          >
            <IconArrow direction="down" color="#fff" />
          </div>
        </div>
        {!showPages && (
          <ArrowSlider
            onNext={() => {
              if (isClickedButtonRef) {
                isClickedButtonRef.current = true;
              }
              onNext();
              logClickButton('Storybook Next Page Big Arrow');
            }}
            onPrev={() => {
              if (isClickedButtonRef) {
                isClickedButtonRef.current = true;
              }
              onPrev();
              logClickButton('Storybook Previous Page Big Arrow');
            }}
            disableNext={activePage === contentLength - 1}
            disablePrev={activePage === 0}
            forceSmall={portrait}
            settings={navSettings}
          />
        )}
        {shouldShowToggleBtn && (
          <div
            className="toggle-pages"
            onClick={() => {
              if (navSettings.hideThumbnails) return;
              togglePagesList(true);
              onToggle?.();
            }}
          >
            <div>
              <strong>{pages.length}</strong>
            </div>
          </div>
        )}
      </div>

      <style jsx global>{`
        .navigation > * {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
      `}</style>
      <style jsx>{`
        .toggle-pages {
          position: absolute;
          border: 2px solid #bbb;
          border-width: 2px 2px 0 0;
          bottom: 20px;
          right: 10px;
          border-radius: 0 5px 0 0;
          padding: 2px 2px 0 0;
          cursor: pointer;
        }
        .toggle-pages > div {
          border: 2px solid #bbb;
          color: #bbb;
          padding: 2px 5px;
          border-radius: 3px;
          margin-left: -4px;
          margin-bottom: -4px;
        }
        .close-pages {
          position: absolute;
          border-radius: 10px 10px 0 0;
          border: 1px solid #444;
          border-bottom: none;
          background-color: #000;
          width: 100px;
          left: 50%;
          transform: translateX(-50%);
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.5s ease;
          top: -28px;
          padding-top: 5px;
          padding-bottom: 0;
          z-index: 2;
        }
        .show .close-pages {
          opacity: 1;
          pointer-events: auto;
        }
        .PageList {
          flex: 0 0 auto;
          border-top: 1px solid #444;
          height: 0;
          position: relative;
          overflow: hidden;
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }

        .PageList-backdrop {
          display: none;
        }
        .show .PageList-backdrop {
          display: block;
        }

        .thumbnail-container {
          white-space: nowrap;
          width: 100%;
          overflow-y: auto;
          display: block;
          padding-left: calc(50% - 75px);
          padding-right: calc(50% - 75px);
        }

        @-moz-document url-prefix() {
          .thumbnail-container .thumbnail:last-child {
            margin-right: calc(50vw - 75px);
          }
        }

        .PageList .thumbnail {
          text-align: center;
          display: inline-block;
          margin: 10px;
          overflow: hidden;
          border-radius: 8px;
          background: #000;
          vertical-align: top;
          border: 2px solid #000;
          padding: 2px;
        }
        .landscape .thumbnail {
          width: 150px;
        }
        .portrait .thumbnail {
          height: 180px;
        }
        .portrait .thumbnail :global(.media-container) {
          width: 84px;
          height: 150px;
        }
        .PageList .thumbnail.active {
          border-color: #fff;
        }

        .navigation {
          color: #fff;
          background: #000;
          border-top: 1px solid #222;
        }
        .page-container {
          position: relative;
        }
        .landscape .show .PageList {
          height: 130px;
          opacity: 1;
        }
        .portrait .show .PageList {
          height: 200px;
          opacity: 1;
        }
        .page-container.show .page-indicator {
          opacity: 0;
          height: 0;
          overflow: hidden;
        }

        .page-container.show + :global(.arrow),
        .page-container.show + :global(.arrow + .arrow) {
          display: none !important;
        }
        .btn {
          outline: none;
          box-shadow: none;
        }
        .btn :global(svg) {
          margin-top: -4px;
        }
        .page-indicator {
          padding: 5px 0;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        .page-indicator div {
          background: #fff;
          height: 10px;
          max-width: 10px;
          width: 100%;
        }
        .page-indicator div {
          opacity: 0.5;
        }
        .page-indicator div:nth-child(odd) {
          opacity: 0;
        }
        .page-indicator div.active {
          background: #fff;
          opacity: 1;
        }

        @media (min-width: 800px) {
          .landscape .thumbnail {
            width: 160px;
          }
          .landscape .page-container.show .PageList {
            height: 142px;
          }
        }
      `}</style>
    </>
  );
};

export default SBMicrositeNavigation;
