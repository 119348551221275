import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDropzone } from 'react-dropzone';
import PopupContainer from '../PopupContainer';
import CropImage from '../../StoryBook/PageDesigner/CropImage';
import { isUserOnMobile } from '../../../../utils/deviceDetector';
import { IConLookup, IconPlayVideo, IconRemove } from '../HubIcons';
import { Spinner } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { generateV4UUID } from '../../../../utils/identityGenerator';
import { uploadToS3 } from '../../../clientSideServices/session';
import { getUploadedFileConvertingStatus } from '../../../clientSideServices/library';
import { logEvent } from '../../../../analytics';
import {
  DID_FAIL_TO_UPLOAD_FILE,
  DID_UPLOAD_FILE
} from '../../../../utils/constants';
import { videoBaseUrl } from '../../../../config';
import { reduceSizeAndWidth } from '../../../utils/image';
import { last } from 'lodash';
import { IAsset } from '../../../../interfaces';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { actionHubAlertError } from '../../../../redux/actions';

const genericMediaErrorMessage = `The media doesn't match our requirement, please review it and try
again.`;

type ImageUploadProps = {
  src: string;
  file?: File;
  alt?: string;
  mediaType?: 'image' | 'video';
  bucket?: string;
  theme?: 'dark' | 'light';
  acceptTypes: string;
  convertType?: string;
  maxSize?: number;
  maxResolution?: {
    width?: number;
    height?: number;
  };
  mode?: 'landscape' | 'portrait';
  roundedInlinePreview?: boolean;
  aspectRatio?: number;
  popupPreview?: boolean;
  crop?: boolean;
  forceToReduceSizeQuality?: number;
  convertQuality?: number;
  onUpload?: (file: File) => Promise<void>;
  onRemove?: () => Promise<void>;
  onClickPreview?: () => Promise<void>;
  onClick?: () => void;
  libraryAsset?: IAsset;
  onSaveAssetUrl?: (url: string) => void;
};

type ImageUploadState = {
  previewUrl: string;
  file?: File;
  needToCrop?: boolean;
  showPopupPreview?: boolean;
  errorMessage?: string;
  loading?: boolean;
  cropped?: boolean;
};

export const ImageUpload: React.FC<ImageUploadProps> = ({
  src,
  alt,
  theme = 'light',
  mediaType,
  aspectRatio,
  maxSize,
  maxResolution,
  mode = 'landscape',
  roundedInlinePreview = false,
  popupPreview = false,
  crop = true,
  forceToReduceSizeQuality,
  convertQuality = 0.8,
  convertType,
  acceptTypes,
  onClickPreview,
  onRemove,
  onUpload,
  onClick,
  file: controlledFile,
  libraryAsset,
  onSaveAssetUrl
}) => {
  const isOnMobile = isUserOnMobile();

  const [
    {
      previewUrl,
      file,
      showPopupPreview,
      needToCrop,
      errorMessage,
      loading,
      cropped
    },
    setState
  ] = useState<ImageUploadState>({
    previewUrl: src
  });

  useEffect(() => {
    setState((prev) => ({ ...prev, previewUrl: src }));
  }, [src]);

  useEffect(() => {
    if (!libraryAsset) {
      return;
    }
    setState((prev) => ({
      ...prev,
      previewUrl: libraryAsset.convertedURL,
      cropped: false,
      errorMessage: ''
    }));
  }, [libraryAsset]);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0] as File;
    if (!file) {
      return;
    }
    setState((prev) => ({
      ...prev,
      file: file,
      previewUrl: URL.createObjectURL(file),
      cropped: false,
      errorMessage: ''
    }));
  }, []);

  useEffect(() => {
    if (!controlledFile) {
      return;
    }
    onDrop([controlledFile]);
  }, [controlledFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptTypes
  });

  const dropContainerProps = getRootProps();

  const handleUpload = useCallback((file: File) => {
    setState((prev) => ({
      ...prev,
      loading: true
    }));
    onUpload?.(file)
      .then((data) => {
        setState((prev) => ({
          ...prev,
          loading: false,
          file: undefined,
          errorMessage: ''
        }));
        return data;
      })
      .catch((error) => {
        console.log('Upload error', error);
        setState((prev) => ({
          file: undefined,
          ...prev,
          errorMessage: 'Upload failed. Please try again.'
        }));
      });
  }, []);

  return (
    <div className={`media-upload-container ${mediaType} ${mode}`}>
      {needToCrop && (
        <CropImage
          imgSrc={previewUrl}
          aspect={aspectRatio}
          onClose={() => {
            setState((prev) => ({
              ...prev,
              needToCrop: false,
              previewUrl: src,
              file: undefined
            }));
          }}
          setCropFile={async (canvas) => {
            let fileType = file?.type
            const croppedFile = await new Promise<File>((resolve) => {
              (canvas as unknown as HTMLCanvasElement).toBlob(
                (blob) => {
                  // error when convert svg image to file
                  (blob as any).lastModifiedDate = new Date();
                  (blob as any).name = libraryAsset
                    ? last(src.split('/'))
                    : file.name;
                  resolve(blob as File);
                  fileType = blob.type;
                },
                convertType || fileType,
                convertQuality
              );
            });
            setState((prev) => ({
              ...prev,
              needToCrop: false,
              previewUrl: URL.createObjectURL(croppedFile),
              file: croppedFile,
              cropped: true
            }));
          }}
          maxWidth={maxResolution.width}
          maxHeight={maxResolution.height}
        />
      )}
      <div
        {...dropContainerProps}
        className={`media-upload-main drop-zone  ${
          isDragActive ? 'drag-active' : ''
        }`}
        onClick={(e) => {
          if (onClick) {
            onClick();
            return;
          }
          dropContainerProps.onClick(e);
        }}
      >
        <input {...getInputProps()} disabled={loading} />

        {previewUrl && (
          <>
            <div className="inline-preview">
              <img
                src={previewUrl}
                alt={alt || 'branding inline preview'}
                className=""
                crossOrigin="anonymous"
                onLoad={async (e) => {
                  const image = e.target as HTMLImageElement;
                  const ratio = image.naturalWidth / image.naturalHeight;
                  const wrongRatio = aspectRatio && ratio !== aspectRatio;
                  const exceedMaxDimensions =
                    image.naturalWidth > maxResolution?.width ||
                    image.naturalHeight > maxResolution?.height;

                  if (libraryAsset?.convertedURL === previewUrl) {
                    const exceedSize = maxSize && libraryAsset.sizeKB > maxSize;
                    const isInvalid =
                      wrongRatio || exceedMaxDimensions || exceedSize;

                    if (!crop && isInvalid) {
                      // special case: force to reduce
                      const uploadFile = await reduceSizeAndWidth(
                        image,
                        libraryAsset.title,
                        libraryAsset.assetType,
                        forceToReduceSizeQuality,
                        maxResolution?.width
                      );

                      handleUpload(uploadFile);
                      return;
                    }
                    if (!cropped && isInvalid) {
                      setState((prev) => {
                        return { ...prev, needToCrop: true, cropped: false };
                      });
                      return;
                    }

                    onSaveAssetUrl?.(libraryAsset.convertedURL);
                    return;
                  }
                  if (file) {
                    const exceedSize = maxSize && file.size > maxSize;
                    const isInvalid =
                      wrongRatio || exceedMaxDimensions || exceedSize;
                    // special case: auto reducing dimensions and size
                    if (!crop) {
                      const uploadFile = exceedSize
                        ? await reduceSizeAndWidth(
                            image,
                            file.name,
                            file.type,
                            convertQuality,
                            maxResolution?.width
                          )
                        : file;

                      handleUpload(uploadFile);
                      return;
                    }

                    if (!cropped && isInvalid) {
                      setState((prev) => {
                        const newState = { ...prev };
                        if (crop) {
                          newState.needToCrop = true;
                        } else {
                          newState.errorMessage = `The media doesn't match our requirement, please review it and try
                    again.`;
                        }
                        return newState;
                      });
                      return;
                    }
                    // special case: force to reduce
                    const uploadFile = forceToReduceSizeQuality
                      ? await reduceSizeAndWidth(
                          image,
                          file.name,
                          file.type,
                          forceToReduceSizeQuality,
                          maxResolution?.width
                        )
                      : file;

                    handleUpload(uploadFile);
                    return;
                  }
                }}
                onError={() => {
                  setState((prev) => ({
                    ...prev,
                    errorMessage:
                      'Failed to load the asset. Please upload new one.'
                  }));
                }}
              />

              {popupPreview && (
                <span
                  className="position-absolute preview-icon shadow-sm  rounded-circle"
                  onClick={(e) => {
                    e.stopPropagation();
                    setState((prev) => ({ ...prev, showPopupPreview: true }));
                    onClickPreview?.();
                  }}
                >
                  <IConLookup />
                </span>
              )}

              <div
                className={`btn-remove position-absolute shadow-sm rounded-circle ${
                  loading ? 'disabled' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (loading) {
                    return;
                  }
                  if (errorMessage && file) {
                    setState((prev) => ({
                      ...prev,
                      previewUrl: src,
                      file: undefined,
                      errorMessage: ''
                    }));
                    return;
                  }
                  setState((prev) => ({
                    ...prev,
                    previewUrl: '',
                    file: undefined,
                    errorMessage: ''
                  }));

                  onRemove?.();
                }}
              >
                <IconRemove />
              </div>
            </div>
          </>
        )}
        {!previewUrl && (
          <div className="px-4 text-center">
            <strong className="drop-zone--info">
              {isOnMobile
                ? 'Tap here to select file'
                : `Drag 'n' drop, or click
          here to select file`}
            </strong>
          </div>
        )}
      </div>
      <div
        className="status d-flex justify-content-center align-items-center"
        style={{ minHeight: 16, marginTop: 4 }}
      >
        {errorMessage && (
          <p className="text-small text-danger">{errorMessage}</p>
        )}
        {loading && <Spinner animation="border" size="sm" />}
      </div>
      <div className="popup-preview">
        {showPopupPreview && (
          <PopupContainer
            onClose={() =>
              setState((prev) => ({ ...prev, showPopupPreview: false }))
            }
          >
            <div
              style={{ maxWidth: 1200 }}
              className="d-flex justify-content-center w-100 pb-2"
            >
              <img
                src={previewUrl}
                alt="brand-image-preview"
                className={`mw-100 ${mode}`}
              />
            </div>
          </PopupContainer>
        )}
      </div>
      <style jsx>{`
        :global(.btn-small) {
          width: 96px !important;
          display: block;
        }
        :global(.inline-preview svg) {
          width: 33.24px;
          height: 33.24px;
        }
        .media-upload-main {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .drop-zone {
          border: ${!previewUrl ? '3px dashed #ddd' : ''};
          border-radius: 10px;
          background: #efefef;
          position: relative;
          outline: none;
          cursor: pointer;
          min-height: 100px;
          min-width: 180px;
        }
        .drop-zone--info {
          font-size: 0.9em;
        }
        .media-upload-main.drag-active {
          border: 2px solid #28a746;
          border-radius: ${roundedInlinePreview ? '6px' : 'none'};
        }

        .inline-preview {
          background-color: ${theme === 'light' ? '#FFFFFF' : '#000000'};
          border-radius: ${roundedInlinePreview ? '4px' : 'none'};
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          border: 1.5px solid #d8d8d8;
        }
        .inline-preview img {
          max-width: 100%;
          max-height: 100%;
        }

        .portrait .popup-preview img {
          width: auto;
          height: 100%;
        }

        .btn-remove {
          top: 8px;
          right: 8px;
          cursor: pointer;
        }
        .btn-remove.disabled {
          cursor: no-drop;
        }
        .preview-icon {
          z-index: 1;
          bottom: 8px;
          right: 8px;
          cursor: pointer;
        }
        .btn-remove .btn-outline-light {
          background-color: #0000006 !important;
        }

        :global(.popup-preview img) {
          max-height: max(300px, 55vh);
        }
        :global(.PopupContainer) {
          overflow-y: auto;
        }
      `}</style>
    </div>
  );
};

type StreamingVideoUploadProps = {
  src: string;
  mediaType?: 'image' | 'video';
  bucket?: string;
  theme?: 'dark' | 'light';
  acceptTypes: string;
  maxSize?: number;
  expectedDimensions?: {
    width?: number;
    height?: number;
  };
  mode?: 'landscape' | 'portrait';
  roundedInlinePreview?: boolean;
  aspectRatio?: number;
  convertQuality?: number;
  onComplete?: (fileUrl?: string) => Promise<void>;
  onRemove?: () => Promise<void>;
  onClickPreview?: () => Promise<void>;
  file?: File;
  onClick?: () => void;
  libraryAsset?: IAsset;
  onSaveAssetUrl?: (url: string) => void;
};

type StreamingVideoState = {
  previewUrl: string;
  file?: File;
  uploadProgress?: number;
  uploadError?: boolean;
  convertingStatus?: 'idle' | 'wait' | 'converting' | 'finished';
  convertingResult?: 'error' | 'success';
  showPopupPreview?: boolean;
  errorMessage?: string;
  loading?: boolean;
};

const filePath = 'content/uploaded/libraries';
const bucket = 'storiez-data';

export const StreamingVideoUpload: React.FC<StreamingVideoUploadProps> = ({
  src,
  theme = 'light',
  maxSize,
  expectedDimensions,
  mode = 'landscape',
  roundedInlinePreview = false,
  acceptTypes,
  onClickPreview,
  onRemove,
  onComplete,
  onClick,
  libraryAsset,
  onSaveAssetUrl,
  file: controlledFile
}) => {

  const dispatch = useDispatch();

  const isOnMobile = isUserOnMobile();
  const playerRef = useRef<ReactPlayer>();

  const videoId = useMemo(() => {
    return generateV4UUID();
  }, [src]);

  const [
    {
      previewUrl,
      file,
      showPopupPreview,
      errorMessage,
      loading,
      convertingStatus,
      convertingResult,
      uploadError,
      uploadProgress
    },
    setState
  ] = useState<StreamingVideoState>({
    previewUrl: src
  });

  useEffect(() => {
    if (src) {
      setState((prev) => ({ ...prev, previewUrl: src }));
    }
  }, [src]);

  useEffect(() => {
    if (libraryAsset) {
      setState((prev) => ({
        ...prev,
        previewUrl: libraryAsset.convertedURL,
        file: undefined,
        errorMessage:
          libraryAsset.sizeKB > maxSize ? genericMediaErrorMessage : ''
      }));
    }
  }, [libraryAsset]);

  const onDrop = useCallback((acceptedFiles, fileRejection?: any[]) => {
    if (fileRejection && !isEmpty(fileRejection)) {
      const err = fileRejection[0].errors?.[0];
      if(err) dispatch(actionHubAlertError(`Error: ${err.message}`));
    }

    const file = acceptedFiles[0] as File;
    if (!file) {
      return;
    }
    setState(() => {
      const previewUrl = URL.createObjectURL(file);
      if (file.size > maxSize) {
        return {
          errorMessage: genericMediaErrorMessage,
          previewUrl
        };
      }
      return {
        file: file,
        previewUrl
      };
    });
  }, []);

  useEffect(() => {
    if (controlledFile) {
      onDrop([controlledFile]);
    }
  }, [controlledFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptTypes
  });

  const dropContainerProps = getRootProps();

  const uploadFilesTos3 = async () => {
    setState((prev) => ({ ...prev, uploadProgress: 1 }));
    const s3Key = `${filePath}/${videoId}/${new Date().toISOString()}-${
      file.name
    }`;
    setState((prev) => ({ ...prev, convertingStatus: 'wait' }));

    uploadToS3(
      file,
      bucket,
      s3Key,
      (progressPercentage) => {
        setState((prev) => ({ ...prev, uploadProgress: progressPercentage }));
      },
      undefined,
      undefined
    )
      .then(() => {
        setState((prev) => ({
          ...prev,
          convertingStatus: 'converting',
          uploadProgress: 0,
          file: undefined
        }));
        const checkConvertingStatus = setInterval(() => {
          getUploadedFileConvertingStatus(videoId).then((items) => {
            const convertedFiles = items[0];
            if (convertedFiles) {
              clearInterval(checkConvertingStatus);
              if (convertedFiles?.metadata?.converted === 'true') {
                const fileUrl = `${videoBaseUrl}/${convertedFiles.files.find(
                  (path) => path.includes('/index.m3u8')
                )}`;
                onComplete(fileUrl);
                setState((prev) => ({
                  ...prev,
                  convertingResult: 'success',
                  convertingStatus: 'finished'
                }));
                return;
              }
              setState((prev) => ({
                ...prev,
                convertingResult: 'error',
                convertingStatus: 'finished'
              }));
              onComplete('');
            }
          });
        }, 3000);
        logEvent(DID_UPLOAD_FILE, DID_UPLOAD_FILE, {
          key: s3Key,
          bucket
        });
        return;
      })
      .catch((error) => {
        console.error(error);
        logEvent(DID_FAIL_TO_UPLOAD_FILE, DID_FAIL_TO_UPLOAD_FILE, {
          key: s3Key,
          bucket,
          error
        });
        setState((prev) => ({ ...prev, uploadError: true }));
      });
  };

  useEffect(() => {
    const checkDimension = function () {
      const height = this.videoHeight;
      const width = this.videoWidth;
      const invalidDimensions =
        expectedDimensions &&
        (height !== expectedDimensions?.height ||
          width !== expectedDimensions?.width);
      if (invalidDimensions) {
        setState((prev) => ({
          ...prev,
          errorMessage: genericMediaErrorMessage
        }));
        return;
      }
      if (file) {
        uploadFilesTos3();
        return;
      }
      if (libraryAsset) {
        onSaveAssetUrl(libraryAsset.convertedURL);
        return;
      }
    }
    if (!file && !libraryAsset) {
      return;
    }
    if (playerRef.current) {
      const video = playerRef.current.getInternalPlayer() as HTMLVideoElement;
      if (video) {
        video.addEventListener('canplaythrough', checkDimension);
      }
    }
  }, [playerRef, previewUrl]);

  const isProcessing =
    (uploadProgress >= 1 && uploadProgress < 100) ||
    convertingStatus === 'converting';

  return (
    <div className={`media-upload-container video ${mode}`}>
      <div
        {...dropContainerProps}
        className={`media-upload-main drop-zone  ${
          isDragActive ? 'drag-active' : ''
        }`}
        onClick={(e) => {
          if (onClick) {
            onClick();
            return;
          }
          dropContainerProps.onClick(e);
        }}
      >
        <input {...getInputProps()} disabled={isProcessing} />

        <div
          className={`inline-preview ${!previewUrl ? 'd-none' : ''}`}
          key={previewUrl}
        >
          <ReactPlayer
            ref={playerRef}
            url={previewUrl}
            controls={false}
            width="100%"
            height="100%"
            onError={() => {
              setState((prev) => ({
                ...prev,
                errorMessage: 'Failed to load the asset. Please upload new one.'
              }));
            }}
          />

          <span
            className="position-absolute preview-icon shadow-sm  rounded-circle"
            onClick={(e) => {
              e.stopPropagation();
              setState((prev) => ({ ...prev, showPopupPreview: true }));
              onClickPreview?.();
            }}
          >
            <IconPlayVideo />
          </span>

          <div
            className={`btn-remove position-absolute shadow-sm rounded-circle ${
              isProcessing ? 'disabled' : ''
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (isProcessing) {
                return;
              }
              if (errorMessage && file) {
                setState((prev) => ({
                  ...prev,
                  previewUrl: src,
                  file: undefined,
                  errorMessage: ''
                }));
                return;
              }
              setState((prev) => ({
                ...prev,
                previewUrl: '',
                file: undefined,
                errorMessage: ''
              }));
              onRemove?.();
            }}
          >
            <IconRemove />
          </div>
        </div>

        {!previewUrl && (
          <div className="px-4 text-center">
            <strong className="drop-zone--info">
              {isOnMobile
                ? 'Tap here to select file'
                : `Drag 'n' drop, or click
          here to select file`}
            </strong>
          </div>
        )}
      </div>
      <div className="status" style={{ minHeight: 16, marginTop: 4 }}>
        {errorMessage && (
          <p className="text-small text-danger">{errorMessage}</p>
        )}
        {loading && <Spinner animation="border" size="sm" />}
        {convertingStatus !== 'idle' && (
          <div className="progress-container">
            {convertingStatus === 'converting' && (
              <div className=" text-small">
                Converting...
                <Spinner animation="border" size="sm" className="float-right" />
              </div>
            )}
            {convertingStatus === 'finished' &&
              convertingResult === 'error' && (
                <div className="text-small text-danger">
                  Converted failed. Please try again.
                </div>
              )}
            {convertingStatus === 'wait' && !uploadError && (
              <div
                className="progress"
                style={{ height: '12px', background: '#efefef' }}
              >
                <div
                  className={`progress-bar ${
                    uploadError ? 'bg-danger' : 'bg-dark'
                  }`}
                  role="progressbar"
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="popup-preview">
        {showPopupPreview && (
          <PopupContainer
            onClose={() =>
              setState((prev) => ({ ...prev, showPopupPreview: false }))
            }
          >
            <div
              style={{ maxWidth: 1024, margin: 'auto' }}
              className="video-preview"
            >
              <ReactPlayer
                url={previewUrl}
                controls={true}
                width="100%"
                height="auto"
                style={{ margin: 'auto' }}
              />
            </div>
          </PopupContainer>
        )}
      </div>
      <style jsx>{`
        :global(.btn-small) {
          width: 96px !important;
          display: block;
        }
        :global(.inline-preview svg) {
          width: 33.24px;
          height: 33.24px;
        }
        :global(.popup-preview .video-preview video) {
          margin: auto;
          max-height: max(55vh, 400px);
        }
        .media-upload-main {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .drop-zone {
          border: ${!previewUrl ? '3px dashed #ddd' : ''};
          border-radius: 10px;
          background: #efefef;
          position: relative;
          outline: none;
          cursor: pointer;
          min-height: 100px;
          min-width: 200px;
        }
        .drop-zone--info {
          font-size: 0.9em;
        }
        .media-upload-main.drag-active {
          outline: 1.5px solid #28a746;
          overflow: hidden;
        }

        .inline-preview {
          background-color: ${theme === 'light' ? '#FFFFFF' : '#000000'};
          border-radius: ${roundedInlinePreview ? '4px' : 'none'};
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          cursor: pointer;
        }

        .btn-remove {
          top: 8px;
          right: 8px;
          cursor: pointer;
        }
        .btn-remove.disabled {
          cursor: no-drop;
        }
        .preview-icon {
          z-index: 1;
          bottom: 8px;
          right: 8px;
          cursor: pointer;
        }
        .btn-remove .btn-outline-light {
          background-color: #0000006 !important;
        }
        .progress-container {
          border-radius: 5px;
          background: #fff;
          position: relative;
          width: 100%;
        }
        .progress-container.error {
          background: #ffdede;
        }
        label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
        label:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          font-size: 0.8em;
        }
        .close-wrapper {
          position: absolute;
          right: 0;
          top: 0;
        }
        .close-wrapper :global(svg) {
          width: 24px;
          height: auto;
        }
        :global(.PopupContainer) {
          overflow-y: auto;
        }
      `}</style>
    </div>
  );
};
