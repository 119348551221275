import React, { useState } from 'react';
import { RiLayoutGridFill } from 'react-icons/ri';
import { IStorybook, StoryBookLayoutPage } from '../../../../interfaces';
import SelectDropdown from '../../Common/SelectDropdown';
import SBSettings from '../Common/SBSettings';
import { StoryBookContext } from '../SBContextContainer';
import { SBActionTarget } from '../SBActionHandler';
import UndoRedoButton from '../../Common/UndoRedoButton';
import { logClickButton } from '../../../../analytics';
import {
  appendNewValueIntoSetting,
  mapStorybookSettingsObjectToString,
  mapStorybookSettingsStringToObject
} from '../../../../mappers/storybook';
import { IconSettings } from '../../Common/HubIcons';

const IconPreview = (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0909 12.7222V1.16667H20V0H0V1.16667H0.909091V12.7222H0V14H20V12.7222H19.0909ZM7.27295 3.8335V10.0557L13.6366 7.03527L7.27295 3.8335Z"
      fill="black"
    />
  </svg>
);

const DesignerPanelHeader = React.memo(({
  onEditFeaturedImage,
}: {
  onEditFeaturedImage?: () => void;
}) => {
  const { action, state, entitlement, visibility } =
    React.useContext(StoryBookContext);
  const selectedStorybook = state.storybook;
  const updateStorybook = (data: IStorybook) => {
    action.update(SBActionTarget.Storybook, { data, append: true });
  };

  const disabled = !entitlement?.allowEdit;

  const handleResetPageSettings = () => {
    const pages = selectedStorybook.pages?.map((page) => {
      const pageSettings = mapStorybookSettingsStringToObject(page.settings);
      const layerSettings = mapStorybookSettingsObjectToString({
        layer: pageSettings.layer
      });
      return {
        ...page,
        settings: layerSettings
      };
    });
    updateStorybook({ pages });
  };

  const hasPages = selectedStorybook?.pages?.length > 0;
  const [forceCloseSetting, setForceCloseSetting] = useState<boolean>(false);
  const pageSettingsPopup = (
    <SelectDropdown
      className="btn btn-sm btn-navigate"
      id={`pageSettingsGlobal`}
      showCloseButton={true}
      label={
        <>
          <IconSettings
            style={{
              width: '25px',
              height: 'auto',
              marginLeft: 0
            }}
          />
          {` `}
          <strong>Default Settings</strong>
        </>
      }
      forceClose={forceCloseSetting}
      setForceClose={setForceCloseSetting}
      popupWidth={400}
      hideArrow={true}
      childrenPosition="before"
      popupXPosition="left"
      options={
        hasPages && !disabled
          ? [
              {
                value: 'apply',
                label: (
                  <div className="btn btn-sm btn-outline-dark btn-round w-100">
                    Apply to all pages
                  </div>
                ),
                onSelect: handleResetPageSettings
              }
            ]
          : undefined
      }
    >
      <div className="inner">
        <SBSettings
          setting={selectedStorybook?.settings || ''}
          onUpdate={
            !disabled ? (settings) => updateStorybook({ settings }) : undefined
          }
          showVideoSettings={true}
          label="Default Settings"
          onUpdateFeaturedImage={(featuredImage) =>
            updateStorybook({
              featuredImage,
              settings: featuredImage
                ? selectedStorybook?.settings
                : appendNewValueIntoSetting(selectedStorybook?.settings, {
                    featuredImage: ''
                  })
            })
          }
          storybookId={selectedStorybook?.id}
          featuredImage={{
            onEdit: onEditFeaturedImage,
            url: selectedStorybook.featuredImage
          }}
        />
        <style jsx>{`
          .inner {
            padding-top: 10px;
          }
          .text-center {
            border-top: 1px solid #ccc;
            padding-top: 10px;
            margin-top: 10px;
          }
        `}</style>
      </div>
    </SelectDropdown>
  );

  const handleNavigate = (navigate: () => void, button: string) => {
    logClickButton(button);
    navigate();
  };
  const pageNavigateButtons = (
    <React.Fragment>
      {visibility.showSortButton && (
        <button
          className="btn btn-sm btn-page-sorter"
          onClick={() =>
            handleNavigate(
              () => action.navigate(StoryBookLayoutPage.SORTER, true),
              'Page Sorter'
            )
          }
          disabled={!entitlement.allowSortingPage}
        >
          <strong>
            <RiLayoutGridFill
              color="#000"
              style={{
                width: '20px',
                height: 'auto',
                marginTop: '-2px'
              }}
            />{' '}
            Page Sorter
          </strong>
        </button>
      )}
      <button
        className="btn btn-sm btn-page-preview"
        onClick={() => {
          handleNavigate(
            () => action.navigate(StoryBookLayoutPage.PREVIEW, true),
            'Preview Storybook'
          );
        }}
        disabled={!entitlement.allowPreviewStorybook}
      >
        <strong>{IconPreview} Preview Storybook</strong>
      </button>
    </React.Fragment>
  );

  return (
    <>
      <div className="panel-header">
        <div className="d-flex align-items-center">
          <div>
            {entitlement.allowEdit && (
              <UndoRedoButton
                onUndo={action.undo}
                onRedo={action.redo}
                disableUndo={!state.undoRedo?.canUndo}
                disableRedo={!state.undoRedo?.canRedo}
              />
            )}
          </div>

          <div className="d-flex justify-content-end">
            {pageNavigateButtons}
            {pageSettingsPopup}
          </div>
        </div>
      </div>

      <style jsx global>{`
        .design-container .col-side:last-child,
        .ContentManager > .panel-content,
        .ContentManager > .panel-header {
          opacity: ${disabled ? 0.3 : 1};
          pointer-events: ${disabled ? 'none' : 'auto'};
        }
        .SBSorter .btn-page-sorter,
        .SBSorter .btn-page-preview {
          display: none;
        }
      `}</style>
      <style jsx>{`
        .panel-header {
          padding: 10px 0;
        }
        .d-flex {
          justify-content: space-between;
        }
      `}</style>
    </>
  );
});

export default DesignerPanelHeader;
